@import 'styles/import.scss';

.titleWrapper {
  padding: 80px 0 40px;
  &.isSmall {
    padding: 80px 40px 40px;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    padding: 120px 40px 40px;
    &.isSmall {
      padding: 80px 48px 40px 40px;
      &.paddingTop {
        padding-top: 120px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 80px 40px;
    &.isSmall {
      padding: 80px 48px 40px 80px;
    }
  }
}

.title {
  @include heading-5;
  color: $blue_dark;
  &.isSmall {
    @include text-md;
    color: $blue;
  }
}

.button {
  margin-top: 8px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
}
